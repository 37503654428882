import axios from "axios";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import toastifyFile from "../external-libraries/React-toastify/index";
import errorConstants from "../../constants/ErrorConstants";
import RoleConstants from "../../constants/RoleConstants";
import AuthHeader from "./AuthHeader";
import axoisConfigUtil from "../../utils/AxoisConfigUtil";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Stack } from "@chakra-ui/layout";
import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/input";
import { BsCheck2Circle } from "react-icons/bs";
import { BiError } from "react-icons/bi";
import languageConstants from "../../constants/LanguageConstants";
import { useEffect } from "react";
import { useUserContext } from "../../context/userContext";

function Login({ language, setLanguage }) {
  let navigate = useNavigate();
  const { t } = useTranslation();

  const { setUserData } = useUserContext();
  const [searchParams, setSearchParams] = useSearchParams();


  useEffect(() => {
    document.title = `${t("title.projectName")} | ${t("home.login")}`;

    if (searchParams.get("notify") != null) {
      sendNotify();
    }

  }, []);

  const formik = useFormik({
    validateOnChange: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t("formik.email"))
        .required(t("formik.required")),
      password: Yup.string().required(t("formik.required")),
    }),
    onSubmit: (values) => {
      login();
    },
  });

  function login() {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/login`,
        {
          email: formik.values.email,
          password: formik.values.password,
        },
        axoisConfigUtil.getHeadersWithLang()
      )
      .then(function (response) {
        toastifyFile.successNotify(
          t("login.messages.success.loggedInSuccessfully")
        );
        localStorage.setItem("token", response.data.access_token);
        setUserData(response.data.user);
        if (response.data.user.role === RoleConstants.USER_ROLE || response.data.user.role === RoleConstants.TESTER_ROLE) {
          navigate("/dashboard");
        }
        if (response.data.user.role === RoleConstants.ADMIN_ROLE) {
          navigate("/admin-dashboard");
        }
      })
      .catch(function (error) {
        document.getElementById("passwordId").value = "";

        if (!error.response) {
          // network error
          toastifyFile.errorNotify(
            t("general.messages.error.connectionRefused") +
            " : " +
            errorConstants.loginPage.code
          );
        }

        if (error.response.status === 401 || error.response.status === 400) {
          formik.setErrors({ password: error.response.data.message });
          // toastifyFile.errorNotify(error.response.data.message);
        } else {
          toastifyFile.errorNotify(
            t("general.messages.error.generalError") +
            " : " +
            errorConstants.loginPage.code
          );
        }
      });
  }

  function sendNotify() {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/contact/notify-telegram`,
        axoisConfigUtil.getHeadersWithLang()
      )
      .then(function (response) {
        toastifyFile.successNotify("Support team will contact you soon");
      })
      .catch(function (error) {
        console.error(error)
      });
  }

  return (
    <>
      <div className='min-h-screen flex flex-col gap-8 items-center bg-[#ebf7fd] custom-bg text-gray-500'>
        <AuthHeader
          pageType='login'
          language={language}
          setLanguage={setLanguage}
        />
        <div className='md:w-1/2 w-3/4 my-auto'>
          <form onSubmit={formik.handleSubmit}>
            <div className='flex flex-col gap-2 p-7 bg-white rounded-tr-2xl rounded-bl-2xl'>
              <Stack spacing={4}>
                <label className='font-light text-xl' htmlFor='email'>
                  {t("signUp.email")}
                </label>
                <InputGroup>
                  <Input
                    id='emailId'
                    name='email'
                    type='mail'
                    required
                    isInvalid={formik.errors.email && formik.touched.email}
                    errorBorderColor='red.300'
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                  />
                  {language === languageConstants.AR ? (
                    <InputLeftElement
                      children={
                        !formik.errors.email && formik.touched.email ? (
                          <BsCheck2Circle className='text-green-700' />
                        ) : formik.errors.email && formik.touched.email ? (
                          <BiError className='text-red-400' />
                        ) : (
                          <></>
                        )
                      }
                    />
                  ) : (
                    <InputRightElement
                      children={
                        !formik.errors.email && formik.touched.email ? (
                          <BsCheck2Circle className='text-green-700' />
                        ) : formik.errors.email && formik.touched.email ? (
                          <BiError className='text-red-400' />
                        ) : (
                          <></>
                        )
                      }
                    />
                  )}
                </InputGroup>
                {formik.errors.email && formik.touched.email && (
                  <div className='text-red-400'>* {formik.errors.email}</div>
                )}

                <label className='font-light text-xl' htmlFor='password'>
                  {t("signUp.password")}
                </label>
                <InputGroup>
                  <Input
                    id='passwordId'
                    name='password'
                    type='password'
                    required
                    isInvalid={
                      formik.errors.password && formik.touched.password
                    }
                    errorBorderColor='red.300'
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                  />
                  {language === languageConstants.AR ? (
                    <InputLeftElement
                      children={
                        !formik.errors.password && formik.touched.password ? (
                          <BsCheck2Circle className='text-green-700' />
                        ) : formik.errors.password &&
                          formik.touched.password ? (
                          <BiError className='text-red-400' />
                        ) : (
                          <></>
                        )
                      }
                    />
                  ) : (
                    <InputRightElement
                      children={
                        !formik.errors.password && formik.touched.password ? (
                          <BsCheck2Circle className='text-green-700' />
                        ) : formik.errors.password &&
                          formik.touched.password ? (
                          <BiError className='text-red-400' />
                        ) : (
                          <></>
                        )
                      }
                    />
                  )}
                </InputGroup>
                {formik.errors.password && formik.touched.password && (
                  <div className='text-red-400'>* {formik.errors.password}</div>
                )}
              </Stack>
              <div
                className='text-left text-[#4050e0] text-sm cursor-pointer w-fit'
                onClick={() => {
                  navigate("/reset-password");
                }}
              >
                {t("login.forgetPassword")}
              </div>
            </div>
            <button
              type='submit'
              className='w-1/3 hover:w-[37%] transition-all mx-auto mt-8 block py-2 text-lg font-light rounded-full text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary'
            >
              {t("login.logIn")}
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default Login;
