import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  CloseButton,
  Flex,
  HStack,
  Text,
  Wrap,
} from "@chakra-ui/react";
import ProjectItem from "./ProjectItem";
import { useState } from "react";
import roleConstants from "../../../constants/RoleConstants";
import { BsCollectionPlayFill } from "react-icons/bs";

export default function ProjectsList({
  projects,
  t,
  fileUploaderDialogOnOpen,
  updatePasswordDialogOnOpen,
  refetchProjects,
  language,
}) {

  const [showChangePasswordPopup, setShowChangePasswordPopup] = useState(localStorage.getItem("update_password") != null ? true : false);

  const user = localStorage.getItem("user") != null;

  return (
    <div>
      {localStorage.getItem("update_password") != null ? (
        <Alert status='info' rounded='xl' margin='2rem auto' width='80%'>
          <AlertIcon />
          <AlertTitle width='full' fontWeight={"normal"}>
            <HStack>
              <Text>{t("updatePasswordDialog.updateMessageNote")} </Text>
              <Text fontWeight={"bold"}>
                {localStorage.getItem("update_password")}
              </Text>
              <button
                className={`m-2 py-1 px-4 text-lg font-light rounded-full text-white bg-primary`}
                onClick={() => {
                  updatePasswordDialogOnOpen(true);
                }}
              >
                <div>{t("updatePasswordDialog.updateMessageAction")}</div>
              </button>
            </HStack>
          </AlertTitle>
          <AlertDescription>
            <CloseButton
              onClick={() => {
                localStorage.removeItem("update_password");
                setShowChangePasswordPopup(false);
              }}
            />
          </AlertDescription>
        </Alert>
      ) : null}

      <Flex justify='space-between' px='40px' pb='30px'>
        <Text px='40px' fontSize='3xl'>
          {t("newProject.clickToEdit")}
        </Text>


        {/*
         Temporarily hidden :
        - USER_ROLE = hidden
        - TESTER_ROLE = show 
         */}
        {/* {
          JSON.parse(localStorage.getItem("user")).role ===
            roleConstants.TESTER_ROLE ?
            <Button
              onClick={fileUploaderDialogOnOpen}
              leftIcon={<BsCollectionPlayFill />}
              background='#00bcee'
              color='#323232'
              fontWeight='light'
              variant='outline'
              _hover={{
                background: "#00bcee",
              }}
              width='300px'
              fontSize='2xl'
              rounded='33px'
              paddingY='2'
              h='50px'
            >
              {t("newProject.createIntractiveVid")}
            </Button>
            : null
        } */}

        {
          <Button
            onClick={fileUploaderDialogOnOpen}
            leftIcon={<BsCollectionPlayFill />}
            background='#00bcee'
            color='#323232'
            fontWeight='light'
            variant='outline'
            _hover={{
              background: "#00bcee",
            }}
            width='300px'
            fontSize='2xl'
            rounded='33px'
            paddingY='2'
            h='50px'
          >
            {t("newProject.createIntractiveVid")}
          </Button>
        }

      </Flex>

      <Wrap p='40px' spacing='24px'>
        {projects.map((project) => (
          <ProjectItem
            t={t}
            key={project.id}
            project={project}
            refetchProjects={refetchProjects}
            language={language}
          />
        ))}
      </Wrap>
    </div>
  );
}
