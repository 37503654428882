import { useParams } from "react-router-dom";

import { useEffect, useState } from "react";
import ViewBuilder from "./ViewBuilder";
import axios from "axios";
import axoisConfigUtil from "../../../utils/AxoisConfigUtil";
import Building from "./Building";
import DashboardHeader from "../dashboard/Header";
import { t } from "i18next";
import ProjectDesign from "./ProjectDesign";
import ProjectSettings from "./ProjectSettings";
import Rules from "./Rules";
import ProjectDeploy from "./ProjectDeploy";
import DashboardNoMatch from "../../noMatch/DashboardNoMatch";
import Loader from "../../Loader";
import { Center } from "@chakra-ui/react";
import projectStatusConstants from "../../../constants/ProjectStatusConstants";
import videosConstants from "../../../constants/VideosConstants";
import ProjectStatistics from "./ProjectStatistics";
import PlatformsConstants from "../../../constants/PlatformsConstants";

function Project({ language }) {
  let { id } = useParams();

  const [viewBuilder, setViewBuilder] = useState(true);
  const [isProjectReady, setIsProjectReady] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const [project, setProject] = useState({});
  const [publishId, setPublishId] = useState("");
  const [referenceUuid, setReferenceUuid] = useState("");
  const [projectRuleCategories, setProjectRuleCategories] = useState({});
  const [enabledTypes, seteEnabledTypes] = useState([]);

  const [isProjectNotFound, setIsProjectNotFound] = useState(false);

  const [notAllowedToNavigateTabs, setNotAllowedToNavigateTabs] = useState(false);

  function getUserProjectById(id) {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/projects/${id}`,
        axoisConfigUtil.getHeadersWithLangAndToken()
      )
      .then((res) => {
        setProject(res.data.project);
        setPublishId(res.data.publish_id)
        setReferenceUuid(res.data.project.store.reference_uuid)
        setProjectRuleCategories(res.data.project_rule_categories);
        seteEnabledTypes(res.data.enabled_option_categories);
        setIsProjectReady(true);
        setIsProjectNotFound(false);
        videosConstants.MAX_FILES = res.data.max_part

        setNotAllowedToNavigateTabs(() => {
          if (res.data.project.status === projectStatusConstants.DEPLOYED.key || res.data.project.status === projectStatusConstants.SUSPENDED_BY_SYSTEM.key || res.data.project.status.startsWith(projectStatusConstants.STOPPED.key)) {
            return true;
          } else {
            return false;
          }
        })
        setActiveTab(() => {
          if (res.data.project.status === projectStatusConstants.DEPLOYED.key || res.data.project.status === projectStatusConstants.SUSPENDED_BY_SYSTEM.key || res.data.project.status.startsWith(projectStatusConstants.STOPPED.key)) {
            return 4;
          } else {
            return 0;
          }
        })
      })
      .catch((error) => {
        if (error.response.status === 404) {
          setIsProjectNotFound(true);
        }
      });
  }

  function updateWidgetDesignStyle(updataedData) {
    let updatedProject = project;
    updatedProject.widget_design.style = updataedData;
    setProject(updatedProject);
  }

  function updateCustomGif(updataedGif) {
    let updatedProject = project;
    updatedProject.widget_design.gifUrl = updataedGif;
    updatedProject.widget_design.is_custom_gif = true;
    setProject(updatedProject);
  }

  function projectRemoveCustomGif(defaultGif) {
    let updatedProject = project;
    updatedProject.widget_design.gifUrl = defaultGif;
    updatedProject.widget_design.is_custom_gif = false;
    setProject(updatedProject);
  }

  function updateProjectName(updatedProjectName) {
    let updatedProject = project;
    updatedProject.name = updatedProjectName;
    setProject(updatedProject);
  }

  function appNewPart(newPart, projectStatus, updatedGif) {
    const parts = project.parts;
    let status = project.status;
    let widget_design = project.widget_design;

    parts.push(newPart);
    status = projectStatus;
    widget_design.gifUrl = updatedGif;

    setProject({ ...project, parts, status, widget_design });
  }

  function removePartById(removedPartId, projectStatus, updatedGif) {
    const parts = project.parts;
    let status = project.status;
    let widget_design = project.widget_design;


    project.parts.map((ele, ind) => {
      if (ele.id == removedPartId) {
        parts.splice(ind, 1);
      }
    });

    status = projectStatus;
    widget_design.gifUrl = updatedGif;

    setProject({ ...project, parts, status, widget_design });

  }

  function updateProjectRules(updatedRuls) {
    const rules = updatedRuls;
    setProject({ ...project, rules });
  }


  function updateProjectStatus(newStatus) {
    let updatedProject = project;
    updatedProject.status = newStatus;
    setProject(updatedProject);
  }

  function navigateToProjectDeploy() {
    console.log("navigateToProjectDeploy");
    setActiveTab(4);
  }

  function navigateToProjectStatistics() {
    console.log("navigateToProjectStatistics");
    setActiveTab(5);
  }

  useEffect(() => {
    getUserProjectById(id);
  }, []);

  if (!isProjectReady) {
    return (
      <Center height='300px' w='full' display='flex' flexDir='column'>
        <DashboardHeader
          activeTab={activeTab}
          setActiveTab={notAllowedToNavigateTabs ? () => null : setActiveTab}
          t={t}
          notAllowedToNavigateTabs={notAllowedToNavigateTabs}
        />
        <Loader isLoading={true} />
      </Center>
    );
  }
  return (
    <div>
      {isProjectNotFound ? (
        <DashboardNoMatch pageLink='dashboard' />
      ) : (
        <>
          <DashboardHeader
            activeTab={activeTab}
            setActiveTab={notAllowedToNavigateTabs ? () => null : setActiveTab}
            t={t}
            notAllowedToNavigateTabs={notAllowedToNavigateTabs}
            navigateToProjectStatistics={navigateToProjectStatistics}
            navigateToProjectDeploy={navigateToProjectDeploy}
          />
          {activeTab === 0 && isProjectReady ? (
            <Building
              t={t}
              project={project}
              setProject={setProject}
              enabledTypes={enabledTypes}
              seteEnabledTypes={seteEnabledTypes}
              appNewPart={appNewPart}
              removePartById={removePartById}
              language={language}
            />
          ) : null}

          {activeTab === 1 && isProjectReady ? (
            <Rules t={t} project={project} projectRuleCategories={projectRuleCategories} updateProjectRules={updateProjectRules} />
          ) : null}
          {activeTab === 2 && isProjectReady ? (
            <ProjectDesign
              t={t}
              language={language}
              widgetDesign={project.widget_design}
              updateWidgetDesignStyle={updateWidgetDesignStyle}
              updateCustomGif={updateCustomGif}
              projectRemoveCustomGif={projectRemoveCustomGif}
            />
          ) : null}

          {activeTab === 3 && isProjectReady ? (
            <ProjectSettings
              t={t}
              projectId={project.id}
              projectName={project.name}
              updateProjectName={updateProjectName}
            />
          ) : null}
          {activeTab === 4 && isProjectReady ? (
            <ProjectDeploy
              t={t}
              language={language}
              projectId={project.id}
              projectStatus={project.status}
              platformIdentify={project.store ? project.store.platForm.platform_identify : PlatformsConstants.activide.platformIdentify}
              publishId={publishId}
              referenceUuid={referenceUuid}
              updateProjectStatus={updateProjectStatus}
              setActiveTab={setActiveTab}
              notAllowedToNavigateTabs={notAllowedToNavigateTabs}
              setNotAllowedToNavigateTabs={setNotAllowedToNavigateTabs}
            />
          ) : null}
          {activeTab === 5 && true ? (
            <ProjectStatistics
              t={t}
              language={language}
              projectId={project.id}
            />
          ) : null}
        </>
      )}
    </div>
  );
}

export default Project;
